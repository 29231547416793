.time-slot__container {
  .dropdowns__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-left: 0;
  }

  .time-slot__error-container {
    margin-top: -24px;
  }
}
