.doctor-info {
  display: flex;
  flex-direction: column;

  &_header {
    margin-bottom: 16px;
  }
  &_body {
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 18px;
  }
  &_small {
  }
  &_medium {
    img {
      height: 48px;
      width: 48px;
    }
  }
  &_big {
  }
}
