.mobile-code-container {
  &_title {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 28px;

    .title-with-number {
      number {
        color: #1771b7;
      }
    }
    margin-bottom: 40px !important;
  }

  &_wrapper-code {
    width: 494px;
    height: 412px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;

    &_img {
    }

    &_field-code {
      display: flex;
      height: 36px;

      &_mobile-code {
        padding-bottom: 88px;

        input {
          font-size: 35px;
          color: rgba(0, 0, 0, 0.87);
          text-align: center;
          margin-right: 12px;
          outline: none;
          padding: 0;
          border: none;
          border-bottom: 2px solid #c5c5c5;
          width: 40px;
        }

        &:focus {
          outline: none;
        }
      }

      &_selector-country {
        .select__menu-list {
          .select__option {
            padding: 9px 9px !important;
            margin: 0 4px !important;
            white-space: nowrap;
            font-size: 13px;
            display: flex;
            align-items: center;
            img {
              margin-right: 2px;
            }
          }
        }
        .select__control {
          width: 63px;
          background: none !important;
        }
      }

      &_input {
        outline: none;
        padding: 0 10px;
        margin-left: 10px;
        border: none;
        border-bottom: 1px solid #dadce0;
      }
    }

    &_buttons {
      display: flex;

      button:first-child {
        margin-right: 30px;
      }
    }
  }
  .mobile-code-patient__request {
    display: block;
  }
}

.font-flag-country {
  font-family:
    Flags Country,
    serif;
}
