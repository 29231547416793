@import "src/styles/colors";
.templates-container {
  background-color: white;
  padding: 20px 40px 40px 40px;

  * {
    white-space: pre-wrap;
  }
  .separator-slash {
    margin: 0 3px;
  }

  &_doc {
    &_mode-preview {
      opacity: 0.5;
    }
  }

  &_preview-block {
    &_overlay {
      position: fixed;
      width: 100vw;
      min-height: 100vh;
      display: flex;
      left: 0;
      top: 100px;
      justify-content: center;
      z-index: -1;
    }

    &_info {
      box-sizing: border-box;
      background-color: white;
      padding: 20px;
      width: 794px;

      span {
        margin: 0 2px;
      }
    }
  }

  &_buttons {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;

    &_preview {
      display: flex;
      align-items: center;
      color: #1771b7;
      font-size: 16px;
      text-transform: uppercase;
      .icon-component {
        margin-right: 9px;
      }
    }

    .container-buttons {
      display: flex;

      button {
        &:first-of-type {
          margin-right: 25px;
        }
      }
      &_save-button {
        position: relative;

        &_button {
          height: 36px;
          color: #ffffff;
          background-color: #1771b7;
          width: 124px;
          padding: 0;
          border-radius: 4px;
          font-size: 16px;
          text-transform: uppercase;
        }
        &_options {
          padding: 5px;
          width: calc(100% - 25px);
          display: none;
          position: absolute;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          bottom: 36px;
          right: 25px;

          button {
            width: 100%;
            font-size: 12px;
          }
        }

        &:hover {
          .container-buttons_save-button_options {
            display: flex;
          }
        }
      }
    }
  }
}

.template-block-container {
  background: $primary10;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: $borderRadius2;

  .emr-notes-wrapper_label,
  .textarea-common-component_placeholder,
  .emr-notes-input-static-text_text {
    background: $primary10 !important;
  }
}
