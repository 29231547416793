@import "src/styles/colors";
.container-sign {
  &_signature-file {
    background: #ffffff;
    border: 1px solid #dadce0;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    padding: 15px;

    &_main {
      margin-right: 16px;
    }

    &_folder {
      text-decoration: none;
      margin-top: 30px;
      position: relative;
      display: flex;
      align-items: center;

      &_format {
        height: 16px;
        display: flex;
        justify-content: center;
        background: $primary80;
        color: white;
        align-items: center;
        padding: 0 5px;
        font-size: 10px;
        position: absolute;
        border-radius: 2px;
        left: -4px;
        bottom: 8px;
      }

      &_green {
        position: absolute;
        top: -6px;
        left: -6px;
      }
    }
  }

  &_signature {
    background: #ffffff;
    border: 1px dashed #dbdce0;
    box-sizing: border-box;
    border-radius: $borderRadius4;
    padding-top: 24px;
    padding-bottom: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
      margin-bottom: 15px;
    }

    &_emr {
      cursor: default;
      align-items: flex-end;
      padding: 16px;
      border: 1px solid #dbdce0;

      > span {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #757575;
        margin-bottom: 12px;
      }
    }
  }
}

.overlay-signature {
  z-index: 10000000;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.7);

  * {
    box-sizing: border-box;
  }

  &_open {
    display: flex;
  }

  &_container {
    width: 751px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: $borderRadius2;
    padding: 30px;
    display: flex;
    flex-direction: column;

    &_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &_body {
      position: relative;

      .sig-canvas {
        border: 1px solid #e9f0fd;
        box-sizing: border-box;
        border-radius: 9px;
        background: #f3f8fb;
      }

      .icon-component {
        right: 11px;
        bottom: 18px;
        position: absolute;
      }
    }

    &_footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
    }
  }
}
