@import "src/styles/colors";

.custom-input-container {
  height: 56px;
  margin-bottom: 25px;
  border-radius: 2px;
  position: relative;
  font-size: 16px;
  width: 100%;

  &_loader {
    border-radius: 2px;
    box-sizing: border-box;
    padding-right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      animation: location 2s infinite normal;
    }

    @keyframes location {
      0% {
        margin-right: 0;
      }

      50% {
        margin-right: 50px;
      }

      100% {
        margin-right: 0px;
      }
    }
  }

  &_dropdown {
    width: 100%;
    position: absolute;
    padding: 10px 0 10px 0;
    max-height: 150px;
    overflow: auto;
    //white-space: nowrap;
    background-color: white;
    box-shadow: $shadowDropdown;
    border-radius: $borderRadius4;
    z-index: 10000;
    top: 60px;
    &_loading {
      width: 100%;
      text-align: center;
    }
    &_item {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      &:hover {
        cursor: pointer;
        background-color: $primary20;
      }

      &.active {
        cursor: default;
        background-color: $primary20;
      }
    }
  }

  &_placeholder {
    z-index: 1;
    top: 18px;
    color: $core50;
    transition: 0.5s;
    position: absolute;
    left: 16px;

    &_isValue {
      color: $core70;
      z-index: 3;
      padding: 0 3px;
      font-size: 12px;
      background-color: white;
      top: -7px;
    }
  }
  &_input {
    color: $core100;
    font-size: 16px;
    box-sizing: border-box;
    background: none;
    position: relative;
    z-index: 2;
    border-radius: 2px !important;
    padding: 16px;
    width: 100%;
    height: 100%;
    border: 1px solid $core50;
    outline: none;
    &:focus + span {
      z-index: 3;
      padding: 0 3px;
      color: $primary80;
      font-size: 12px;
      background-color: white;
      top: -7px;
    }
    &:focus {
      border-color: $primary80 !important;
    }

    &_out-served-area {
      border: 1px solid $pinch;
    }
  }
  &_error {
    .custom-input-container_input {
      border: 1px solid #b00020;
    }
    .custom-input-container_input + span {
      color: #b00020;
    }
  }
  &_disable {
    .custom-input-container_input {
      color: $core40;
      border-color: $core30;
    }
    .custom-input-container_placeholder {
      color: $core30;
    }
  }
}

.address-fields-verification {
  padding: 20px 0;
}

@media (max-width: 576px) {
  .custom-input-container {
    * {
      font-size: 14px !important;
    }
  }
}
