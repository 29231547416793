@import "src/styles/colors";
.container-buttons_save-button {
  position: relative;
  margin-left: 25px;

  &_button {
    height: 36px;
    color: #ffffff;
    background-color: $primary80;
    width: 124px;
    padding: 0;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
  }
  &_options {
    padding: 5px;
    width: 100%;
    display: none;
    position: absolute;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    bottom: 36px;

    > button {
      margin: 0 !important;
      width: 100%;
      font-size: 12px !important;
    }
  }

  &:hover {
    .container-buttons_save-button_options {
      display: flex;
    }
  }
}
