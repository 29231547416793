.icon-button {
  &_big {
    svg {
      font-size: 32px;
    }
  }
  &_medium {
    svg {
      font-size: 24px;
    }
  }
  &_small {
    svg {
      font-size: 16px;
    }
  }

  &_default {
    color: #5f6368 !important;
  }
  &_primary {
  }
  &_secondary {
  }
}
