.btn-component {
  font-family: "GoogleSans Regular" !important;
  .MuiButton-label {
    text-transform: none;
  }

  &_uppercase {
    .MuiButton-label {
      text-transform: uppercase;
    }
  }
  &_capitalize {
    .MuiButton-label {
      text-transform: capitalize;
    }
  }
  &.MuiButtonBase-root {
    min-width: auto;
    height: 36px;
    font-size: 16px;
    line-height: 24px;

    &.MuiButton-textPrimary {
      color: #1771b7;

      &:hover {
        background: #f3f8fb;
      }
    }

    &.MuiButton-contained {
      background: #f3f8fb;
      color: #1771b7;
      box-shadow: none;
      border: 1px solid #1771b7;

      &:hover {
        background-color: #e8f1f8;
      }

      &.MuiButton-containedPrimary {
        background: #1771b7;
        color: white;

        &:hover {
          background-color: #227cc2;
        }
      }
    }
  }

  .MuiButton-startIcon {
    margin: 0;
  }
}
