.additional-services {
  margin-top: 20px;

  &_item {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 165px);
    }

    .container-info {
      width: 145px;
      display: flex;
      justify-content: space-between;

      &_cost {
        font-weight: bolder;
        margin-right: 20px;
      }

      &_status {
      }
    }
  }
}
