.radio-item {
  position: relative;
  white-space: nowrap;

  &_input {
    position: absolute;

    margin-right: 3px;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  &_text {
    margin-left: 25px;
    display: inline-flex;
    align-items: center;
    line-height: 16px;

    &_negative {
      margin-right: 3px;
    }
  }
  //&_checked{
  //  background-color: #1771B7;
  //}
}
