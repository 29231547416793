.all-request-details {
  width: 55%;
  overflow: auto;
  padding-right: 60px;
  margin-right: 10px;
  .date-time__wrapper {
    margin-bottom: 20px;
    margin-left: 4px;
    gap: 16px;
  }
  .date-time__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date-time__label {
      letter-spacing: 0.15px;
      font-weight: 400;
      line-height: 22px;
      margin-right: 4px;
    }
    .date-time__elem {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }
  &_create-notes {
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;
  }
}
