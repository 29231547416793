@import "src/styles/colors";
.emr-notes-input-static-text-container {
  .emr-notes-input-static-text {
    position: relative;
    border: 1px solid $primary80;
    border-radius: $borderRadius2;
    margin: 2px 5px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;

    &_error {
      border-color: #b00020;
    }

    &_text {
      width: 40px;
      border: none;
      text-align: center;
    }
    &_devider {
      display: inline-block;
      width: 55px;
      text-align: center;
    }
  }
}
