.doctor-page-dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 1298px;
  margin: 0 auto;

  .right-card {
    .doctor-page-dashboard_expand.icon-component {
      top: 24px;
    }
  }
  .left-card {
    .doctor-page-dashboard_expand.icon-component {
      top: 17px;
    }
  }

  &_expand {
    position: absolute;
    top: 13px;
    right: 22px;

    &.right-expand {
      top: 4px;
      right: 0;
    }
  }

  &_container {
    display: flex;
    justify-content: space-between;

    &_chart {
      .doctor-page-dashboard_expand.icon-component {
        top: 9px !important;
      }
    }
    &_card {
      position: relative;

      .card-info__patient {
        position: absolute;
        top: -54px;
        left: 275px;
      }
    }
  }

  &_filters {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
  }

  .right-card {
    width: 966px;

    .chart-wrapper {
      display: flex;
      justify-content: space-between;

      .left {
        width: 650px;
        padding-right: 28px;

        .chart-container {
          height: 300px;
        }
        .notes-statistics__container {
          display: flex;
          align-items: center;
          margin-bottom: 32px;
        }
      }

      .right {
        position: relative;
        border-left: 1px solid #dadce0;
        padding: 0 28px;

        .dashboard-right__container {
          display: flex;
          align-items: center;
        }

        .total-text {
          width: 100%;
          text-align: center;
          padding-top: 72px;
        }
      }
    }
  }

  .left-card {
    width: 308px;
  }

  .stat-wrapper {
    display: flex;
    justify-content: space-between;

    .item {
      width: 33%;

      .title {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}
