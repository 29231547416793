.progress-stepper {
  display: flex;
  align-items: center;

  .round-wrapper {
    height: 64px;
    width: 64px;
    margin: 0;
  }

  &_text-data {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
  }
}
