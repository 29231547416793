@import "src/styles/colors";
.app-container-request {
  position: relative;
  background-color: #fff;

  &_confirm-message-content {
    .popup-component-common-content_body {
      padding-top: 20px;
    }
  }

  &_title-steps {
    display: flex;
    flex-direction: column;
    padding-left: 35%;
    padding-right: 30%;
    margin-bottom: 40px;
  }
  &_header-panel {
    padding-top: 40px;
    display: flex;

    &__navigation {
      z-index: 3;
      width: 100%;
      justify-content: space-between;
      padding: 25px 0;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      box-shadow: $shadowTopBar;
      background-color: #fff;
    }

    &_icon-back {
      display: flex;
      width: 25%;
      align-items: center;
      justify-content: center;
    }

    //&_hide-steps {
    //  .app-container-request_header-panel_icon-back {
    //    width: 15%;
    //  }
    //}

    &_icon-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      .icon-component {
        cursor: pointer;
      }
    }
    &_text {
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    &_label {
      margin-top: 20px;
    }

    &__title {
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;

      &__status {
        display: flex;
        width: 50%;

        &__text {
          &--title,
          &--subtitle {
            display: block;
          }
          &--title {
            margin-bottom: 15px;
          }
          &--subtitle {
            font-size: 20px;
          }
        }

        &__indicator {
          margin-right: 32px;
          .round-wrapper {
            width: 64px;
            height: 64px;
            margin: 0;
          }
        }
      }

      &__space {
        width: 25%;
      }
    }
  }
  &_wrapper {
    padding-bottom: 40px;
    display: flex;
    width: 100%;
    &_left-panel {
      width: 25%;
      display: flex;
      justify-content: center;
      transition: 0.5s;
      &_hide {
        //width: 15%;
        opacity: 0;
      }
      .patients-list {
        width: 100%;
        max-width: 300px;
        min-height: 400px;
        max-height: calc(100vh - 400px);
        overflow: auto;
        padding-right: 10px;
        &__wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 0 15px;
        }
      }
    }
    &_right-panel {
      width: 50%;
      * {
        box-sizing: border-box;
      }

      //&_hide-steps{
      //  width: 70%;
      //}
      &_main-actions {
        //max-height: calc(100vh - 300px);
      }

      &_navigation-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        button:first-child {
          margin-right: 30px;
        }
      }
      .list-patients {
        &_list {
          padding-left: 3px;
          padding-right: 10px;
        }
        .card-patient_info {
          overflow: hidden;
          &_data {
            overflow: hidden;
            span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        &__group-name {
          padding: 0 21px;
          color: #777;
          font-size: 16px;
          line-height: 20px;

          &:not(:first-of-type) {
            margin-top: 24px;
          }

          .checkbox-component {
            margin: 0;

            .MuiButtonBase-root {
              margin-right: 20px;
              padding: 0;
            }

            .MuiTypography-root {
              color: #777;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .step-symptoms-list {
    max-height: calc(100vh - 400px);
    overflow: auto;

    &_wrapper {
      max-height: none;

      &_data-item_wrapper_name {
        word-break: break-word;
      }

      &_isTextarea {
        max-height: none;
      }
    }

    .textarea-common-component_textarea {
      margin-bottom: 0;
    }
  }
  .patients-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .step-symptoms-list {
      max-height: calc(100vh - 400px);
      overflow: auto;
      margin-top: 12px;
    }

    &__symptoms-list {
      width: 100%;
    }

    &__item {
      padding: 20px;
      border-radius: 2px;
      border: 1px solid #dadce0;
      cursor: pointer;

      &--active {
        box-shadow: 0 1px 8px rgba(14, 67, 109, 0.16);

        .patient-card__arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  .user-data__info {
    margin: 0;
  }
}

@media (min-width: 900px) {
  .app-container-request_header-panel_hide-steps {
    .app-container-request_header-panel_icon-back,
    .app-container-request_header-panel_icon-close {
      width: 20%;
    }

    .app-container-request_header-panel_text {
      width: 60%;
    }
  }

  .app-container-request_wrapper_right-panel {
    justify-content: center;
    display: flex;
    width: 50%;

    .app-container-request_wrapper_right-panel_main-actions {
      width: 100%;
    }
  }
}
//@media(min-width: 900px){
//  .app-container-request{
//    &_header-panel{
//      &_hide-steps{
//        .app-container-request_header-panel_text{
//          width: 70%
//        }
//      }
//    }
//    &_wrapper{
//      &_right-panel{
//        &_hide-steps{
//          display: flex;
//          justify-content: center;
//          width: 70%;
//        }
//      }
//    }
//  }
//}

@media (max-width: 1200px) {
  .app-container-request {
    &_header-panel {
      padding-top: 80px;
      &_text {
        width: 50%;
        padding: 0 25%;
      }
      &_title {
        font-size: 42px;
      }
    }
    &_wrapper {
      padding-bottom: 100px;
      &_right-panel {
        width: 50%;
        .details-request-cards {
          min-height: 500px;
        }
        &_navigation-buttons {
          z-index: 3;
          margin-top: 0;
          padding: 24px 5%;
          width: 100%;
          position: fixed;
          left: 0;
          bottom: 0;
          box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          display: flex;
          justify-content: center;

          button {
            width: 162px;
          }
        }

        .list-patients_list {
          overflow: auto;
          max-height: none;
        }

        .patients-list {
          .step-symptoms-list {
            max-height: initial;
            overflow: initial;
          }
        }
      }
      &_left-panel {
        .user-data img {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .app-container-request {
    .details-request-cards_list {
      width: 100%;
      padding: 30px;

      .personal-info-patient_add-card {
        width: 100%;
      }
    }
    .details-request-cards_list_options {
      margin-top: 0;
    }

    &_header-panel {
      &_icon-close {
        width: 15%;
      }
      &_text {
        width: 70%;
        padding: 0 15%;

        > span {
          font-size: 32px;
        }
      }

      &_icon-back {
        width: 15%;
      }

      &__title {
        margin-top: 40px;
        margin-bottom: 30px;
        &__status {
          width: 70%;
        }
        &__space {
          width: 15%;
        }
      }
    }

    &_wrapper {
      &_right-panel {
        width: 70%;

        &_main-actions {
          .patient-info-container {
            > span {
              display: block;
              margin-top: 24px;
            }
          }

          .mobile-code-container {
            &_wrapper-code {
              width: 100%;
            }
          }

          .step-symptoms-list {
          }
          .step-time-defining {
            margin-top: 16px;

            &_container-buttons {
              &_button {
                button {
                  span {
                    white-space: nowrap;
                  }
                }
              }
            }

            &_title {
              > span {
              }
            }
          }
          .details-request-cards {
            margin-left: 0;
          }
        }
      }
      &_left-panel {
        width: 15%;

        .patients-list {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .app-container-request {
    &_header-panel {
      &_text {
        width: 80%;
        padding: 0 10%;
        > span {
          font-size: 28px;
        }
      }
      &_icon-close,
      &_icon-back {
        width: 10%;
      }
      &__title {
        &__status {
          width: 80%;
        }
        &__space {
          width: 10%;
        }
      }
    }

    &_wrapper {
      &_right-panel {
        width: 80%;
        &_main-actions {
          .mobile-code-container {
            &_title {
              margin-bottom: 16px !important;
              > span {
                font-size: 16px;
                line-height: 24px;

                &:first-of-type {
                }
                margin-top: 0 !important;
              }
            }
            &_wrapper-code {
              .container-input-flag {
                height: initial;
                flex-direction: column;
                .container-input-flag_select-flag {
                  justify-content: center;
                  display: flex;
                }
                input {
                  text-align: center;
                }
              }
            }
          }

          .container-info-rewiew {
            &_title {
              > span {
                display: block;
                margin-top: 15px;
              }
            }
          }
        }
      }
      &_left-panel {
        width: 10%;
      }
    }
  }
}

@media (max-width: 576px) {
  .app-container-request {
    .details-request-cards_list {
      padding: 16px;
    }
    .details-request-cards_list_buttons {
      button {
        width: 100% !important;
      }
    }
    &_header-panel {
      padding-top: 80px;
      &_text {
        width: 90%;
        padding: 0 5%;
        > span {
          font-size: 18px;
        }
      }
      &_icon-close,
      &_icon-back {
        width: 5%;
      }
      &_icon-close {
        margin-right: 21px;
      }
      &_icon-back {
        margin-left: 21px;
      }
      &__title {
        margin-top: 24px;
        margin-bottom: 24px;
        &__space {
          width: 5%;
        }
        &__status {
          width: 90%;
          &__text {
            line-height: 20px;
            &--title {
              margin-bottom: 5px;
              font-size: 18px;
            }
            &--subtitle {
              font-size: 12px;
            }
          }
          &__indicator {
            margin-right: 16px;
            .round-wrapper {
              width: 44px;
              height: 44px;
              margin: 0;
              div {
                line-height: 11px;
              }
              span {
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    &_wrapper {
      &_left-panel {
        width: 5%;
      }
      &_right-panel {
        width: 90%;
        &_main-actions {
          .mobile-code-container {
            &_wrapper-code {
              &_field-code_mobile-code {
                padding-bottom: 0;

                input {
                  font-size: 20px;
                  width: 25px;
                  margin-right: 0;
                }
              }
              &_buttons {
                width: 100%;
                flex-direction: column;
                button {
                  width: 100%;

                  &:last-of-type {
                    margin-top: 16px;
                  }
                }
              }
            }
          }

          .container-info-rewiew {
            &_gift-card {
              &_wrapper {
                flex-direction: column;
                //123
                .custom-input-container {
                  height: initial;
                  min-height: 56px;
                  width: 100%;
                }
                button {
                  margin-top: 24px;
                  width: 100% !important;
                }
              }
            }
            &_title {
              margin-bottom: 16px;
              > span {
                font-size: 16px;
              }
            }
            .component-card-request {
              &_user-data {
                img {
                  display: none;
                }
              }
            }
          }
          .patient-info-container {
            &_title-block_second {
              margin-bottom: 15px;
            }
            > span {
              font-size: 16px;
            }
            &_block-row {
              flex-direction: column;

              &.half-width {
                width: 100%;
              }
            }
          }
          .list-patients {
            > span {
              font-size: 16px;
            }
            &_list {
              .card-patient {
                margin-top: 16px;
              }
            }
            &_buttons-choice {
              margin-top: 16px;
              justify-content: space-between;
              button {
                width: calc(50% - 10px);
              }
            }
            &_buttons-actions {
              flex-direction: column;
            }
          }
          .step-symptoms-list {
            &_title {
              margin-bottom: 6px;

              > span {
                font-size: 16px;
              }
            }
            &_wrapper_data-item {
              height: initial;
              min-height: 56px;

              &_wrapper_name {
                padding-right: 15px;
              }
            }
          }

          .step-time-defining {
            margin-top: 16px;

            &_title {
              margin-bottom: 16px;
              > span {
                font-size: 16px;
              }
            }
            &_time-wrapper {
              width: 100%;
              flex-direction: column;

              > div {
                width: 100%;
                .single-date-picker {
                  width: 100%;
                }
              }

              &_time {
                width: 100% !important;
                margin-top: 16px;
              }
            }

            &_container-buttons {
              width: 100%;
              margin-top: 16px;
              margin-bottom: 0;
              flex-direction: column;
              &_button {
                width: 100%;

                button {
                  width: 100%;

                  &:last-of-type {
                    margin-top: 16px;
                  }

                  span {
                    white-space: nowrap;
                  }
                }
              }
            }
            &_container-buttons_active {
              justify-content: center;
              width: 100% !important;
            }
          }
        }
        &_navigation-buttons {
          button {
            width: 100%;
            &:first-child {
              margin-right: 19px;
            }
          }
        }
      }
    }
  }

  .container-info-rewiew_container-buttons
    .app-container-request_wrapper_right-panel_navigation-buttons
    button:last-of-type {
    width: 100% !important;
  }
}
