.request-info-event {
  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__type {
    margin-top: 12px;
  }

  &__time {
    display: flex;
    justify-content: space-between;

    &-period {
      text-transform: lowercase;
    }
  }

  &_status {
    display: flex;
    align-items: center;

    span:nth-child(2) {
      margin-left: 4px;
      margin-right: auto;
    }

    svg {
      cursor: pointer;
      color: #bac3c9;
    }
  }
}
