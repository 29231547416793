@use "sass:meta" as ---cwdeglz70dv;$primary80: #1e427b;
$primary70: #2758a4;
$primary100: #0c1a31;
$primary10: #f3f6fc;
$primary20: #e6ecf9;
$primary50: #6390d9;
$primary60: #417ad2;
$primary40: #9cb9e7;
$primary30: #cedcf3;

$core30: #dde0e4;
$core20: #f4f5f6;
$core40: #bac0c9;
$core50: #98a1ae;
$core100: #090a0b;
$core70: #5e6878;
$core80: #434a56;
$core10: #f9fafb;

$borderRadius2: 2px;
$borderRadius4: 4px;
$borderRadius8: 8px;
$borderRadius10: 10px;

$primary: #1771b7;
$primaryOLD40: rgba($primary, 0.4);

$error: #f86161;
$error40: rgba($error, 0.4);

$shadowDropdown: 0px 4px 16px 4px rgba(39, 88, 164, 0.08);
$itemRequestshadow: 0px 2px 12px 2px rgba(65, 122, 210, 0.2);
$shadowActive: 0px 4px 16px 4px rgba(39, 88, 164, 0.08);
$shadowCard: 0px 1px 8px rgba(14, 67, 109, 0.16);
$shadowNotification: 0px 2px 24px rgba(30, 66, 123, 0.14);
$shadowPopup: 0px 4px 20px rgba(9, 45, 73, 0.12);
$shadowSidebar: -2px 0px 8px rgba(16, 78, 127, 0.16);
$shadowBottomBar: 0px -2px 8px rgba(9, 68, 114, 0.08);
$shadowTopBar: 0px 2px 8px rgba(9, 68, 114, 0.08);

$gold: #cb9a2a;
$pinch: #d3970d;
$error-dark: #ce1212;
$error-light-1: #fdc4c4;
$warning: #f3bd41;
$warningLight: #fbebc5;

;@include ---cwdeglz70dv.load-css("sass-embedded-legacy-load-done:1");