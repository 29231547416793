@import "src/styles/colors";
.popup-component-common-content {
  position: relative;
  box-shadow: $shadowPopup;
  &_close {
    position: absolute !important;
    right: 18px !important;
    top: 18px !important;
  }
  &_header {
    padding-right: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    &_wrapper-titles {
      display: flex;
      flex-direction: column;
      margin-right: 24px;
    }
  }
  &_body {
  }
  &_footer {
    display: flex;
    justify-content: flex-end;

    button:last-child {
      margin-left: 20px;
    }
  }

  &__button {
    &-primary.button-common-component {
      min-width: 124px;
      width: auto;
      padding: 8px 14px;
      box-sizing: border-box;
      max-width: 152px;
    }
  }
}

@media (max-width: 576px) {
  .patient-flow-container,
  .app-container-request {
    .popup-content {
      width: calc(100% - 32px) !important;

      .popup-component-common-content_header_wrapper-titles {
        padding-right: 15px;
      }
    }
    .app-container-request_confirm-message-content,
    .login-form-code-content {
      width: calc(100% - 92px) !important;

      .login__code-container {
        input {
          font-size: 20px;
          width: 25px;
          margin-right: 0;
        }
      }

      &_mobile {
        padding-bottom: 40px;
        align-items: center;
        flex-direction: column;

        .container-input-flag_select-flag {
          margin-bottom: 10px;
          width: 70px !important;
          align-items: center;
        }
      }
    }
  }
}

.zip-code-popup-content {
  .button-common-component {
    white-space: nowrap !important;
    span {
      white-space: nowrap !important;
    }
  }
}
