@import "src/styles/colors";
.checkbox-component {
  .MuiCheckbox-root {
    //padding: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 18px;
    font-family: "GoogleSans Regular";
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
  }
  .MuiSvgIcon-root {
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $primary80;
  }
}
