@import "src/styles/colors";
.container-page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $primary80;
  flex-direction: column;
  &_number {
    font-weight: bold;
    font-size: 196px;
    line-height: 267px;
    text-transform: capitalize;
  }
  &_message {
    font-size: 48px;
    line-height: 60px;
  }

  button {
    background: $primary80;
    border-radius: 4px;
    color: white;
    margin-top: 88px;
    width: 184px;
    height: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
}
