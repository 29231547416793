@import "src/styles/colors";
.emr-notes-wrapper {
  display: inline-block;
  width: 280px;
  position: relative;
  position: relative;
  margin: 5px;

  &_open {
    z-index: 1000;
  }

  &_error-text {
    position: absolute;
    top: 60px;
  }

  &_disabled {
    opacity: 0.7;
  }

  &_error {
    .emr-notes-drop-down__control {
      border-color: #b00020 !important;
    }
  }

  &_label {
    z-index: 1;
    top: 14px;
    color: rgba(0, 0, 0, 0.6);
    transition: 0.5s;
    position: absolute;
    left: 16px;

    &_isValue,
    &_isOpen {
      left: 9px;
      line-height: 12px;
      padding: 0 7px;
      font-size: 12px;
      background-color: white;
      top: -5px;
    }
    &_isValue {
      transition: 0s;
      z-index: 3;
    }
    &_isOpen {
      z-index: 6;
    }
    &_error {
      color: #b00020 !important;
    }
  }

  .emr-notes-container {
    z-index: 2;
    outline: none;

    &.emr-notes-container_isOpen {
      z-index: 5;
    }

    .emr-notes-drop-down__single-value {
      font-size: 16px;
      color: #333d47;
    }

    .emr-notes-drop-down__multi-value {
      background: #1771b7;
      border-radius: 100px;
      height: 23px;
      margin: 3px 13px 3px 0;

      .emr-notes-drop-down__multi-value__remove {
        color: #ffffff;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        width: 17px;
        height: 17px;
        position: relative;
        left: -3px;
        top: 3px;
        margin-left: 28px;
        transition:
          color 0.3s,
          background-color 0.3s;

        &:hover {
          cursor: pointer;
          color: #1771b7;
          background-color: #f7f8f9;
        }
      }

      .emr-notes-drop-down__multi-value__label {
        color: #ffffff;
        font-size: 16px;
        line-height: 17px;
        padding: 3px 3px 3px 10px;
      }
    }

    .emr-notes-drop-down__option {
      background-color: transparent;
      padding: 14px 16px;
      margin: 0;
      width: auto;
      border-radius: $borderRadius2;

      &:hover {
        background: $primary20;
      }

      &--is-selected {
        color: #414549;
        background: #f5f6f7;
      }
    }

    .emr-notes-drop-down__placeholder {
      color: #707e8b;
      font-size: 16px;
    }

    .emr-notes-drop-down__menu {
      margin-top: 1px;
      z-index: 3;
      box-shadow: $shadowDropdown;

      &-list--is-multi {
        //.emr-notes-drop-down__option--is-selected {
        //  background: transparent;
        //}
      }
    }

    .emr-notes-drop-down__control {
      padding-right: 30px;
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
      //background: #F4F4F4;
      border-radius: 4px;
      outline: none !important;
      box-shadow: none;
      min-height: 44px;
      position: relative;
      max-height: 250px;
      overflow-y: auto;

      .emr-notes-drop-down__value-container {
        padding: 3px 30px 4px 13px;
      }

      .indicator {
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 5px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
        right: 16px;
        top: 20px;
        color: #707e8b;
      }
      &--menu-is-open {
        .indicator {
          border-top: none;
          border-bottom: 5px solid;
        }
      }
    }
  }

  .error {
    .emr-notes-drop-down__control {
      &:after {
        content: "";
        bottom: 0;
        position: absolute;
        width: calc(100% - 8px);
        left: 4px;
        height: 2px;
        border-radius: 2px 2px 0 0;
        background-color: #ff3c3c;
      }
    }
  }
}
