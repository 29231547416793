@import "src/styles/colors";

.create-appointment {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  z-index: 1100;
  padding: 40px 72px 140px 72px;
  box-sizing: border-box;

  &_progress_stepper {
    margin: 32px 0 32px 0;
  }

  &_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg path {
      fill: $core70;
    }
  }

  &_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $core20;
    box-sizing: border-box;

    &_next-button {
      margin-left: auto !important;
    }
  }

  .table-header-cell span {
    padding-right: 0;
  }

  .ReactVirtualized__Table__headerColumn:first-of-type {
    margin-left: 0;
  }

  .ReactVirtualized__Grid .container-radio-button {
    margin-left: 12px;
    margin-top: 6px;
  }
}
