@import "src/styles/colors";
.custom-date-range-picker {
  display: flex;
  height: 56px;
  width: 360px;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid $core40;
  border-radius: 3px;

  &_disabled {
    opacity: 0.4;
  }

  &_container-date-from,
  &_container-date-to {
    display: flex;
    align-items: center;
    position: relative;
    width: 50%;
    padding-left: 16px;

    .react-datepicker-wrapper {
      height: 22px;
    }

    .container-input-picker {
      > span {
        color: $core100;
      }
    }
    .container-input-picker_text {
      border: none;
      padding: 0;
    }
    .wrapper-custom-date-picker {
      margin-bottom: 0;
    }
    &_label {
      font-size: 12px;
      top: -23px;
      left: 15px;
      background: white;
      padding: 0 6px 0 2px;
      color: $core70;
      letter-spacing: 0.4px;
      position: absolute;
    }
  }

  &_container-date-from {
    &:after {
      content: "";
      width: 1px;
      height: 30px;
      position: absolute;
      right: 0;
      top: -3px;
      background-color: $core40;
    }

    &_from {
      .container-input-picker_img {
        margin-right: 14px;
      }
      svg {
        path {
          fill: $primary80;
        }
      }
    }
  }
}
