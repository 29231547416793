@import "src/styles/colors";
.request-details-other {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid $core30;
  box-sizing: border-box;
  border-radius: $borderRadius2;
  overflow: hidden;
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_icon {
      path {
        fill: #5f6368;
      }
    }
  }
  &_body {
    height: 0;
    display: flex;
    flex-direction: column;

    .additional-services {
      margin-top: 0;

      &_item {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    &_symptoms {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
      span {
        margin: 4px 4px 0 0;
      }
    }

    &_comment {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      comment {
        margin-top: 16px;
        padding: 16px;
        background: #f8f8f8;
        border-radius: 4px;
        white-space: pre-wrap;
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background: #dadce0;
    margin: 30px 0;
  }

  &_open {
    .request-details-other_body {
      height: inherit;
      .emr-sidebar {
        > button {
          width: initial !important;
        }
      }
    }
  }
}

.container-sign_signature-file,
.container-sign_signature {
  margin-top: 20px;
}
