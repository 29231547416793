@import "src/styles/colors";

.add-shift-popup {
  &_recurrence {
    position: relative;

    &_txt-confirm {
      position: absolute;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      color: #63727e;
      left: 0;
      top: 35px;
    }

    .radio-block-component {
      display: flex;
      flex-direction: column;
    }

    .checkbox-component {
      margin-bottom: 20px;
    }

    .single-date-picker {
    }

    margin-bottom: 24px;

    &_first-block {
      display: flex;
      align-items: center;

      .icon-component {
        margin-left: 8px;
      }

      .custom-input-container {
        margin-right: 24px;
        margin-bottom: 0;
        width: 150px;
      }

      button:last-of-type {
        margin-left: 16px;
      }

      button {
        border-radius: 8px;
      }
    }

    &_second-block {
      display: flex;
      flex-direction: column;
      padding-top: 24px;

      &_label {
        margin-left: 12px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #63727e;
        padding-bottom: 12px;
      }

      &_days {
        margin-bottom: 24px;
        display: flex;

        &_item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          color: $core70;
          background-color: $core30;
          border-radius: 50%;
          margin-right: 12px;

          &_active {
            background-color: $primary80;
            color: white;
          }
        }
      }
    }
  }

  &_wrapper-time {
    display: flex;
    justify-content: space-between;

    &_top {
      margin-top: 25px;
    }

    & > div {
      width: calc(50% - 5px);
    }

    &_back-up {
      margin-bottom: 25px;
    }
  }
}

.reassing-physician-container-overlay {
  //z-index: 1001 !important;
  .popup-component-common-content_body {
    margin-top: 25px;
  }
}

.shedule-container-event-shift {
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_not-selected {
    opacity: 0.4;
  }

  &_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 12px;
    line-height: 20px;
  }

  &_icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &_accepted {
    background: $primary80;
    border: 1px solid $primary20;
    color: #ffffff;
  }

  &_assigned {
    background: $primary20;
    border: 1px solid $primary80;
    color: $primary80;
  }

  &_no-doctor {
    background: $error-light-1;
    border: 1px solid $error-dark;
    color: $error-dark;
  }

  &_is-minified {
    padding: 2px 6px;
    justify-content: center;
    .shedule-container-event-shift_patient {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &_amount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    &-wrapper {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &_shift-data {
    display: flex;
    flex-direction: column;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_status {
    text-align: right;
    width: 100%;
    text-transform: capitalize;
  }

  &_status,
  &_patient {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_patient {
    font-weight: 800;
    width: 100%;
    font-size: 14px;
  }

  &_declined {
    background: $core30;
    color: $core70;
    border: 1px solid $core70;

    .shedule-container-event-shift_status {
      color: $error-dark;
    }
  }
}

.add-shift-popup_confirmation-delete-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .delete {
    margin-right: auto;
  }
}
