.followup_note {
  position: absolute;
  width: 595px;
  padding: 40px;
  z-index: -1;
  left: -700px;

  .line {
    width: 100%;
    height: 1px;
    background: black;
    margin-top: 15px;
  }
  .bold {
    margin-bottom: 15px;
    height: 3px;
  }
  &_name {
    padding-bottom: 10px;
  }
  txt {
    font-size: 14px;
    font-family: "GoogleSans Regular", sans-serif;
  }

  .main-context {
    &_br {
      height: 20px;
      width: 100%;
      display: block;
    }
  }
}
