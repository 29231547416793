.pcp-container-form {
  width: 100%;
  margin-top: 30px;

  &_row {
    display: flex;

    & > div:first-of-type {
      margin-right: 5px;
    }

    & > div:last-of-type {
      margin-left: 5px;
    }

    &.half {
      width: 50%;

      & > div:last-of-type {
        margin-left: 0;
      }
    }
  }
  &_row-radio {
    label {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }
    &_title {
      margin-bottom: 16px;
      display: block;
    }
  }
}
