@import "src/styles/colors";
.popup-filters-container {
  &_buttons {
    display: flex;
    justify-content: flex-end;

    button:first-of-type {
      margin-right: 16px;
    }
  }

  &_container {
    box-shadow: $shadowPopup;
  }
  &-arrow {
    display: none;
  }

  &-content {
    z-index: 1100 !important;
  }
}
