.templates-footer {
  &_signature {
    font-family: "GoogleSans Italic", sans-serif;
    font-weight: bold;
    margin-bottom: 0;

    img {
      height: 100px;
    }
  }
}
