@import "src/styles/colors";
.container-info-rewiew {
  width: 100%;
  position: relative;

  .container-map {
    margin-top: 32px;

    &_address-container_text span {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  .personal-info-patient_add-card_info_form {
    .container {
      height: initial;
    }
  }

  &__agreements {
    margin-left: -10px;
    .MuiFormControlLabel-root.checkbox-component {
      margin: 0;
      vertical-align: initial;

      .MuiCheckbox-root {
        margin-right: 10px;
      }
    }
  }

  &__links {
    line-height: 24px;
    letter-spacing: 0.15px;
    a {
      text-decoration: none;
      color: $primary70 !important;
    }
  }

  &_card-container {
    margin-top: 80px;
  }
  .add-card-popup-content {
    width: 750px !important;
    .popup-component-common-content_body {
      padding-top: 20px;

      .container-fields-card-create {
        display: flex;
        justify-content: space-between;

        .custom-input-container {
          width: calc(50% - 10px);
        }
      }

      .rccs__card--unknown {
        .rccs__card--front {
          color: #717579;
          background: #e9f0fd;

          * {
            opacity: 1 !important;
          }
        }
      }

      .rccs__card--amex {
        .rccs__card--front {
          color: white;
        }
      }

      .rccs__card--mastercard {
        .rccs__card--front {
          color: #717579;
        }
      }

      .rccs__card--visa {
        .rccs__card--front {
          color: white;
        }
      }

      .container-button-create-card {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &_container-buttons {
    display: flex;

    .app-container-request_wrapper_right-panel_navigation-buttons {
      button {
        &:last-of-type {
          width: 186px !important;
        }
      }
    }
  }

  &_form {
    margin-top: 25px;
  }

  &_signature {
    display: inline-block;
    margin-top: 16px;
  }

  &_title {
    margin-bottom: 40px;
  }

  &_wrapper-map {
    border: 1px solid #e8e8e8;
    overflow: hidden;
    border-radius: 6px;
    height: 173px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  &_address-title {
    display: inline-block;
    margin-top: 30px;
  }
  &_calculated {
    width: 100%;
    display: flex;
    flex-direction: column;
    &_row {
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      grey {
        color: #757575;
      }
    }
  }
  &_gift-card {
    display: flex;
    align-items: center;
    margin: 40px 0;
    justify-content: space-between;
    .button-common-component {
      width: 144px !important;
    }
    .custom-input-container {
      width: calc(100% - 144px - 25px);
      margin-bottom: 0;
    }
    &_previe {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 15px;
      align-items: center;
      position: relative;
      display: flex;
      height: 56px;
      width: 100%;
      padding-right: 120px;
      justify-content: space-between;

      .icon-component {
        position: absolute;
        right: 15px;
      }
    }
    &_wrapper {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &_container {
    display: flex;
    flex-direction: column;
    &_price {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .price {
        &__type,
        &__total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        &__request-name {
          margin-left: 10px;
        }

        &__amount {
          margin-right: 80px;
        }

        &__request-price {
          line-height: 28px;
          font-size: 22px;
        }

        &__gift-card {
          display: flex;
          justify-content: space-between;
        }

        &__row {
          &:not(:first-of-type) {
            margin-top: 16px;
          }
        }
      }
    }
    &_what-is-included {
      margin-top: 18px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &_address {
    margin-top: 17px;
    display: flex;

    &_img {
      margin-right: 16px;
    }
    &_wrapper-address {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      &_text {
        line-height: 17px;
      }
    }
  }
  &__patients {
    margin-top: 20px;
    .patients-list {
      display: block;

      &__item {
        cursor: initial;
      }

      .user-data {
        overflow: hidden;
        &_text {
          overflow: hidden;
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.component-card-request {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius2 $borderRadius2 $borderRadius8 $borderRadius8;
  padding: 20px 16px 0 16px;

  &_title {
    display: flex;
    justify-content: space-between;

    &_patient {
      height: 28px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      background: #f3f8fb;
      border-radius: 8px;
    }
  }

  &_user-data {
    margin-top: 12px;
    display: flex;

    &_img {
      height: 64px;
      width: 64px;
      border-radius: 50%;
    }

    &_data {
      display: flex;
      flex-direction: column;
      margin-left: 18px;

      &_initials {
      }

      &_date {
      }

      &_phone {
      }
    }
  }

  &_symptoms {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;

    .symptom {
      margin-top: 8px;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }

  &_comment-block {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 16px;
    &_title {
      line-height: 24px;
    }

    &_text {
      font-style: italic;
      line-height: 20px;
      margin-top: 12px;
    }
  }

  &_wrapper {
    grid-gap: 16px;
    display: flex;
  }
  &_author-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &_title {
      line-height: 24px;
    }

    &_date {
      margin-top: 8px;
      display: flex;
      grid-gap: 8px;
    }

    &_container {
      display: flex;
      margin-top: 12px;

      &_author {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 24px;
      }
    }

    &_phone {
      line-height: 24px;
      margin-top: 4px;
    }
  }

  &_price-block {
    margin-top: 16px;
    padding-right: 16px;
    padding-left: 20px;
    width: calc(100% + 32px);
    transform: translateX(-16px);
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(243, 243, 243, 0.6);
    border-top: 1px dashed #b8b8b8;

    &_what-is {
      text-decoration: underline;
    }

    &_container-price {
      display: flex;
      justify-content: flex-end;
    }

    &_label {
    }

    &_summary {
      font-weight: 500;
    }

    &_right,
    &_left {
      position: absolute;
      width: 15px;
      height: 30px;
      top: -15px;
      box-shadow: 0 1px 4px inset rgba(0, 0, 0, 0.2);
      background: white;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 28px;
        background-color: white;
        top: 1px;
        left: -4px;
        border-radius: 0 4px 4px 0;
      }
    }

    &_right {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      right: 0;

      &:after {
        left: 11px;
        border-radius: 4px 0 0 4px;
      }
    }
    &_left {
      border-left: 0;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      left: 0;
    }
  }
}

.custom-marker-on-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background: rgba(23, 113, 183, 0.1);
  border-radius: 50%;
  &_marker {
    border-radius: 50%;
    background: #1771b7;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
  }
}

.component-form-stripe {
  display: flex;
  flex-direction: column;
  &_card,
  &_date,
  &_cvc {
    font-size: 18px;
    height: 34px;
    background: none;
    border-radius: 4px;
    padding: 6px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  label {
    display: inline-block;
    margin-top: 10px;

    span {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
  &_container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.what-is-included-content {
  .popup-component-common-content_body {
    .additional-information {
      line-height: 24px;
      display: inline-block;
      margin: 20px 0 30px 0;
    }
    .options {
      display: flex;
      flex-direction: column;

      &_item {
        display: flex;
        margin-bottom: 20px;

        span {
          line-height: 20px;
          &:first-of-type {
            margin-right: 15px;
          }
        }

        &:last-of-type {
          margin-bottom: 40px;
        }
      }
    }
  }
  .popup-component-common-content_footer {
    justify-content: center;

    .button-common-component {
      width: 284px;
      margin: 0;
    }
  }
}

.container-info-rewiew_gift-card_title {
  margin-bottom: 12px !important;
  display: block;
}

@media (max-width: 576px) {
  .container-info-rewiew {
    &_container-buttons {
      .app-container-request_wrapper_right-panel_navigation-buttons {
        button {
          &:last-of-type {
            width: 100% !important;
          }
        }
      }
    }
    &_container {
      display: flex;
      flex-direction: column;

      &_price {
        .price__amount {
          margin-right: 40px;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .container-info-rewiew {
    &_container {
      display: flex;
      flex-direction: column;

      &_price {
        .price {
          &__amount {
            margin-right: 15px;
          }
          &__request-name {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
