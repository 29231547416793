@import "src/styles/colors";

.doctors-list {
  width: 100%;

  &_divider {
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }

  .container-radio-button {
    height: 30px;
  }

  &_add-button {
    margin-left: auto !important;

    &_icon {
      margin-right: 8px;
    }
  }
}
