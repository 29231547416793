@import "src/styles/colors";
.container {
  height: 18px;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_disabled {
    opacity: 0.3;
  }

  &_error {
    color: #b00020;
    .checkmark {
      border: 2px solid #b00020;
    }
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #757575;
  border-radius: 2px;
  box-sizing: border-box;
}

///* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f3f8fb;
}

//
///* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $primary80;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
