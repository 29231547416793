@import "src/styles/colors";
.emr-page-container {
  width: 100%;
  padding-top: 30px;

  &_checkbox {
    display: flex;
    flex-direction: column;

    &_item {
      margin-bottom: 30px;
    }
  }

  &_panel-filters {
  }

  &_table {
    margin-top: 24px;

    &_row {
      &:hover {
        cursor: pointer;
        background-color: $primary10;
        .custom-table-cell-actions {
          opacity: 1;
        }
      }
    }
  }
}

.notes-label {
  padding: 6px 0 20px 0;
}

.popup-filters {
  &-container-content {
    &_label-checkbox {
      margin: 30px 0 0 1px;
    }
    &_label-checkbox:last-of-type {
      margin: 30px 0 43px 1px;
    }
    &_label-checkbox:first-of-type {
      margin: 23px 0 30px 1px;
    }
  }
}

.table-header-cell {
  span {
    height: 100%;
    line-height: 24px;
    padding-bottom: 4px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }
}

.custom-table-cell-actions {
  display: flex;
  opacity: 0;
  transition: 0.2s ease-out;
  justify-content: space-between;
  padding-right: 19px;
  .icon-delete {
    margin-left: 32px;
  }
}
