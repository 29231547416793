.patient-card {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &_user {
    display: flex;
    grid-gap: 16px;
    &_note {
      grid-gap: 4px;
      display: flex;
      flex-direction: column;
      width: 100%;
      &_space {
        height: 4px;
      }
      * {
        word-break: break-all;
      }
    }
  }

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  &__arrow {
    position: absolute;
    top: 25px;
    right: 20px;
    transition: 0.3s ease;
  }

  &__symptoms {
    //margin-left: -16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .symptom {
      margin-top: 8px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }

  &__comment {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    &--text {
      margin-top: 8px;
      font-style: italic;
    }
  }

  &__open-emr {
    position: absolute !important;
    top: 0;
    right: 0;
    height: auto;

    .MuiButton-label {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
