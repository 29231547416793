.checkbox-item {
  white-space: nowrap;
  position: relative;
  &_name {
    position: absolute;
    margin-right: 3px;
    width: 20px;
    display: inline-block;
    height: 20px;
  }

  &_text {
    margin-left: 25px;
  }

  &_input {
    margin-left: 3px;
    display: inline-block;
    min-width: 100px;
    border: 1px solid #1771b7;
    border-radius: 4px;
    position: relative;
    &_text {
      width: 100%;
      padding: 0 5px;
      display: inline-block;
    }
    &_overlay {
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    &_error {
      border: 1px solid #b00020;
    }
    &_disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  //&_checked{
  //  background-color: #1771B7;
  //}

  &_datepicker {
    height: 20px;
    width: 135px;
  }
  &_datepicker-container {
    margin-left: 10px;
    display: inline-block;
  }
}
