@import "src/styles/colors";
.follow-attachement-notes {
  box-sizing: border-box;
  margin-top: 20px;

  &_complete-request {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;
  }

  &_notes-button {
    display: flex;
    justify-content: flex-end;
  }

  &_li-delete,
  &_li-download,
  &_li-edit,
  &_li-delete {
    display: flex;
    align-items: center;
    .icon-component {
      display: flex !important;
    }

    span {
      position: static !important;
    }
  }
  &_li-delete {
    .icon-component {
      margin-left: 12px !important;
      margin-right: 23px !important;
    }
  }
  &_li-download {
  }

  &_li-edit {
    .icon-component {
      margin-left: 9px !important;
      margin-right: 22px !important;
    }
  }
  &_li-delete {
    .icon-component {
      margin-left: 12px !important;
      margin-right: 24px !important;
    }
  }

  &_notes-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 23px;
    justify-content: space-between;

    &_actions {
      display: flex;
      align-items: center;
    }

    &:hover {
      .notes-item_menu {
        display: inline-block;
      }
    }
    &_status {
      top: 4px;
      right: 40px;
      position: absolute;
    }
  }

  .container-with-actions__request {
    background: #ffffff;
    border: 1px solid $core30;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    padding: 16px;
    margin-top: 20px;
  }

  .attachment-input-file {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}

#template {
  position: fixed;
  top: 10000px;
}

.selecting-template {
  margin-top: 25px;
}

.notes-options {
}
