.step-time-defining {
  display: flex;
  align-items: center;
  flex-direction: column;

  &_title {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    align-items: center;
  }
  &_container-buttons {
    margin-top: 40px;
    display: flex;

    &_button {
      display: flex;
      align-items: center;
      flex-direction: column;
      &:first-child {
        margin-right: 20px;
      }
      &_typography {
        margin-top: 11px;
      }
      > button {
        border-radius: 4px !important;

        .button-common-component_img {
          width: initial !important;
          height: initial !important;

          > svg {
            width: initial !important;
            height: initial !important;
          }
        }
      }
    }
    &_active {
      padding-left: 10px;
      width: 190px !important;
      justify-content: space-between;
    }
  }
  &_time-wrapper {
    margin-top: 40px;
    border-top: 1px solid #dadce0;
    padding-top: 40px;
    display: flex;
    width: 100%;
    justify-content: center;

    &_hour {
      margin-left: 64px;
    }

    &_time {
      width: 227px !important;
    }

    &_spacer {
      height: 100%;
      width: 23px;
    }
  }
  &_img {
    width: 214px;
    height: 214px;
  }
}
