.receipt-container-editor-content {
  width: 1000px !important;

  .receipt-container__wrapper {
    height: 90%;

    .drop-down-wrapper {
      margin: 0;
    }
    .optional-templates-container_preview-block__receipt {
      font-size: 26px;
      font-weight: bold;

      .templates-header__receipt {
        display: flex;
        flex-direction: column;
        font-size: 18px;

        .header-img {
          width: 100%;
        }

        .header-title {
          text-align: center;
          font-size: 18px;
        }
      }

      .header__doctor-info__receipt {
        font-size: 18px;
        text-align: left;
        margin-top: 20px;

        .doctor-info {
          font-weight: bold;
        }

        .doctor-email {
          color: black;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background: black;
        margin-top: 30px;
      }

      .bold {
        margin-bottom: 30px;
        height: 3px;
      }

      .templates-footer__receipt {
        &_signature {
          font-family: "GoogleSans Italic", sans-serif;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      .receipt-context {
        font-weight: bold;

        &__sum {
          margin-top: 40px;
        }

        table {
          border: 1px solid black;
          font-family: arial, sans-serif;
          border-collapse: collapse;
        }

        td,
        th {
          border: 1px solid black;
          text-align: left;
          padding: 8px;
          .custom-input-container {
            margin-bottom: 0;
          }
        }

        .table-service {
          width: 100% !important;
          .error-table_data {
            td {
              border: 3px solid red;
            }
          }
        }
      }
    }
  }
}
