//
//.login__code {
//  &-pin {
//    width: 288px;
//    overflow: hidden;
//  }
//  &-container {
//    left: 0;
//    position: sticky;
//  }
//  &-input {
//    padding-left: 8px;
//    letter-spacing: 33px;
//    border: 0;
//    background-image: linear-gradient( to left, black 70%, rgba(255, 255, 255, 0) 0% );
//    background-position: bottom;
//    background-size: 50px 1px;
//    background-repeat: repeat-x;
//    background-position-x: 35px;
//    width: 290px;
//    min-width: 220px;
//    font-size: 30px;
//    otline: none
//  }
//}
//

.login__code {
  &-pin {
    overflow: hidden;
  }
  &-container {
    .react-code-input {
      input {
        text-align: center;
        font-size: 32px;
        font-family: "GoogleSans Regular" !important;
        width: 40px;
        background: none;
        border: none;
        border-bottom: 1px solid #c5c5c5;
        margin-left: 12px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  &-input {
    display: flex !important;
  }
}
