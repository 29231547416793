.gift-cards-container {
  display: inline-block;
  padding-top: 15px;

  .custom-date-range-picker {
    margin-top: 21px;
  }
  .button-common-component {
    margin-top: 21px;
    width: 100% !important;
  }
}
