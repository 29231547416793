.personal-patient-card {
  border: 1px solid #dadce0;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 24px 20px;
  background: white;

  .separator {
    background: #dadce0;
    width: 100%;
    height: 1px;
    margin-top: 30px;
  }
}
