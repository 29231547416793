.ReactVirtualized__Table__headerRow {
  &:hover {
    background-color: initial !important;
    cursor: initial !important;
  }
}
.placeholder-table-empty {
  display: flex;
  justify-content: center;
}
