@import "src/styles/colors";
.count-patients {
  &_active {
    padding: 24px 20px;
    border: 1px solid #dadce0;
    border-radius: 10px;
    .count-patients_header {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #757575;
      margin-bottom: 14px;
    }
  }
  &_with-note {
    .count-patients_body_patients {
      span {
        color: #434e56;
      }
    }
  }
  &_header {
  }
  &_body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_patients {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        height: 48px;
        width: 48px;
      }
      span {
        margin-left: 8px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $primary80;
      }
      svg {
        fill: #bac3c9;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 576px) {
  .count-patients {
    &_active {
      .count-patients {
        &_body {
          align-items: flex-start;
          flex-direction: column;

          button {
            margin-top: 16px;
            margin-left: auto;
          }
          &_patients {
            img {
              display: block;
            }
            span {
            }
          }
        }
      }
    }
    &_header {
    }
  }
}
