.row-container {
  padding: 7px 10px;
  border-radius: 50px;
  background: #e3e3e3;
  color: rgba(0, 0, 0, 0.6);
  height: 15px;
  display: table;
  text-align: center;
  white-space: normal;
}

.archive {
  .ReactVirtualized__Table__headerTruncatedText {
    overflow: auto;
    white-space: normal;
    text-overflow: initial;
  }

  .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }
}
