.container-add-new-doctor {
  width: 62%;
  margin: 0 auto;

  &_title {
    display: block;
    margin: 40px 0 43px 0;
  }
  &_title-block {
    display: flex;
    justify-content: space-between;

    .custom-input-container {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  &_title-btn {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    width: 278px;
    margin-bottom: 32px;
  }

  &__btn {
    margin-top: 40px;
  }
}

.archive-popup {
  font-size: 34px;
  line-height: 36px;
  &:last-of-type {
    margin-bottom: 36px;
  }
}
