.typography {
  &-h0 {
    font-size: 104px;
  }
  &-h1 {
    font-size: 48px;
  }
  &-h1_5 {
    font-size: 36px;
  }
  &-h1_75 {
    font-size: 32px;
  }
  &-h2 {
    font-size: 28px;
  }
  &-h2_5 {
    font-size: 24px;
  }
  &-label {
    font-size: 20px;
  }
  &-title {
    font-size: 18px;
  }
  &-p {
    font-size: 16px;
  }
  &-h3 {
    font-size: 22px;
  }
  &-h4 {
    font-size: 14px;
  }
  &-h5 {
    font-size: 12px;
    letter-spacing: 0.4px;
  }
  &-h6 {
    font-size: 8px;
    letter-spacing: 0.1px;
  }
  &-link {
    font-size: 16px;
    text-decoration: none;
  }
}
