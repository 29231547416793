@import "src/styles/colors";
.component-filter-value {
  margin-right: 13px;
  display: flex;
  height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  background: $primary10;
  border-radius: 50px;
  align-items: center;
  border: 1px solid $primary80;
  &_img {
    margin-left: 13px;
  }
  svg {
    path {
      fill: $primary80;
    }
  }
}
