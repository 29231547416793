@import "src/styles/colors";

.summary-step {
  width: 100%;
  overflow-y: auto;
  padding: 16px 20%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  &_patient {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 30px;

    &_card {
      margin-top: 12px;
      padding: 24px 20px;
      border-radius: 2px;
      border: 1px solid $core30;
    }
  }

  &_charge-type {
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 32px;
    border-top: 1px solid $core30;
    display: flex;
    justify-content: flex-end;
  }

  .container-info-rewiew_container_price {
    margin-top: 24px;
  }

  &_doctor {
    margin-bottom: 30px;
    border-radius: 2px;
    border: 1px solid $core30;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;

    &_title {
      margin-bottom: 12px;
    }
  }
}
