@import "src/styles/colors";

.admin-page-container {
  width: 100%;
  //min-width: 1200px;
  &_panel-filters {
    margin-top: 30px;
  }

  &_table {
    margin-top: 24px;

    &_row {
      &:hover {
        cursor: pointer;
        background-color: $primary10;
        .custom-table-cell-actions {
          opacity: 1;
        }
      }
    }
  }
}

.invite_status_cell {
  display: flex;
}
.custom-indicator-account-status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.uploaded-files-number-cell {
  display: flex;
  justify-content: center;
}

.table-header-cell {
  span {
    height: 100%;
    line-height: 24px;
    padding-bottom: 4px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }
}

.custom-table-cell-actions {
  display: flex;
  opacity: 0;
  transition: 0.2s ease-out;
  justify-content: space-between;
  padding-right: 19px;
}

.admin-shifts-calendar {
  padding-top: 40px;

  &_visit-file {
    margin-top: 24px;
  }
  &_create-appointment {
    margin-top: 24px;
  }

  &_filters-radiobuttons {
    padding: 0 20px;
    margin-top: 30px;
    .container-radio-button {
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }

  &_filters-container {
    padding: 0;
    margin-top: 30px;
    .container-panel-filter_trigger-button-isValue {
      padding: 0;
      .component-filter-value {
        margin-right: 0;

        > span {
          line-clamp: 2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .container-panel-filter_left-part {
      width: 100%;
      .container-panel-filter_trigger-button {
        width: 100%;
      }
    }
  }

  &_upload-file {
    margin-top: 30px;
    padding: 0 20px;

    &_text {
      padding: 7px 12px;
      text-align: center;
      background: $primary80;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
