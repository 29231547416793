@use "sass:meta" as ---cwdeglz70dv;.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

;@include ---cwdeglz70dv.load-css("sass-embedded-legacy-load-done:25");