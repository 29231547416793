.edit-request-container {
  display: none;
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  background: white;
  z-index: 1009;
  left: 0;
  box-sizing: border-box;
  padding: 40px 20vw 64px 20vw;
  &_open {
    display: flex;
  }
}

.edit-request {
  width: 100%;
  position: relative;
  &_close {
    position: absolute !important;
    right: 0;
    top: 0;
  }
  &_header {
    font-size: 48px;
    line-height: 56px;
    justify-content: center;
    display: flex;
    margin-bottom: 52px;
    color: rgba(0, 0, 0, 0.87);
  }
  &_item {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    .single-date-picker {
      width: 100%;
    }
    > div {
      width: calc((100% - 10px) / 2);
      margin-bottom: 0 !important;
    }
    .textarea-common-component_textarea {
      height: calc(100% - 25px);
    }

    &_checkbox {
      margin-top: 16px;
    }
  }
  &_title {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    font-size: 22px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);

    > button.icon-button {
      transform: translateX(14px);
    }
  }

  &_patient {
    margin-bottom: 60px;

    &:last-of-type {
      margin-bottom: 100px;
    }
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 65px;

    button {
      &:nth-of-type(2) {
        margin-left: auto;
      }
      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}
