.main-container-app {
  width: 100%;
  min-width: 1200px;
  padding: 0 64px;
  box-sizing: border-box;
  height: 100%;

  &_header {
    width: calc(100% + 128px);
    transform: translateX(-64px);
  }
}

@media (max-width: 900px) {
  .main-container-app {
    padding: 0 16px;

    &_header {
      width: calc(100% + 32px);
      transform: translateX(-16px);
    }
  }
}
