@import "src/styles/colors";
.button-menu {
  display: inline-block;
  padding-top: 0;
  span {
    position: relative;
    top: -4px;
  }
  position: relative;

  button {
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    padding: 7px 15px;

    .icon-component {
      display: inline-block;
      margin-left: 7px;
    }
  }

  .menu-list {
    z-index: 1000;
    position: absolute;
    display: none;
    background: #ffffff;
    box-shadow: $shadowDropdown;
    border-radius: 6px;
    list-style-type: none;
    padding: 8px 0;
    margin: 0;
    min-width: 140px;
    right: -16px;
    top: 35px;

    li {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      padding: 6px 10px;
      cursor: pointer;
      div {
        margin-right: 19px;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
  }
  &:focus-within {
    .menu-list {
      display: block !important;
    }
  }

  button:focus {
    .menu-list {
      display: block !important;
    }
  }

  &_horizontal {
    button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      background: #f3f8fb;
      border: 1px solid $primary80;
      box-sizing: border-box;
      border-radius: 4px;

      .button-menu_icon {
        display: flex;
        transform: rotate(90deg);
        svg {
          path {
            fill: $primary80;
          }
        }
      }
    }
  }
}
