@import "src/styles/colors";
.container-sidebar {
  position: fixed;
  height: 100%;
  width: 436px;
  right: -440px;
  background-color: white;
  box-shadow: $shadowSidebar;
  top: 0;
  z-index: 1003;
  border-radius: $borderRadius4 0 0 $borderRadius4;
  transition: right 1s;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  .separator {
    margin: 30px 0;
    background: #dadce0;
    width: 406px;
    height: 1px;
  }

  .multiple-visits {
    &__list {
      margin-top: 28px;
      margin-bottom: 5px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      cursor: pointer;

      .shedule-container-event-shift_not-selected {
        opacity: 1;
      }

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }

    &__patient-name {
      font-size: 14px;
      line-height: 20px;
    }

    &__request {
      text-align: end;
      font-size: 10px;
      line-height: 20px;
    }

    .doctor-info-event {
      width: 100%;
      display: flex;
      flex-direction: column;

      &_container-data {
        display: flex;
        justify-content: space-between;
      }

      &_extra {
        border: 1px solid #dadce0;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 12px 24px;
        line-height: 24px;
      }
    }
  }

  .textarea-common-component {
    margin-top: 20px;
  }

  &__header {
    flex-direction: column;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: #fff;
    padding: 25px 31px 20px 34px;
    box-shadow: $shadowTopBar;
  }

  &__content {
    padding: 10px 25px 25px 34px;
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - 218px);
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 34px;
    padding-right: 31px;
    height: 96px;
    box-shadow: $shadowBottomBar;

    button {
      min-width: 132px !important;
    }

    .container-sidebar__button {
      &--additional {
        min-width: auto !important;
        width: 40px !important;
      }

      &--secondary {
        padding: 6px;
      }
    }
  }

  &__main-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button:last-of-type {
      margin-left: 16px;
    }
  }

  &__controls {
    height: 21px;
    display: flex;
    margin-bottom: 21px;

    &_close {
      svg > path {
        fill: #5f6368;
      }
    }
  }

  &--open {
    right: 0;

    &_decline {
      overflow: initial;
    }
  }

  & .side-bar_text {
    display: block;
    white-space: nowrap;
    font-size: 28px;
  }
  & .side-bar_patient {
    background: #f3f8fb;
    border-radius: 8px;
    padding: 5px 15px;
    margin: 0 0 30px 0;
    display: block;
    width: 171px;
    text-align: center;
  }

  & .side-bar__btn-patient {
    width: 180px !important;
  }

  & .doctor-present__header {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #5f6368;
    display: block;
    margin-top: 28px;
  }
  & .doctor-present__info {
    display: block;
    margin-top: 8px;
    width: 80%;
  }

  & .side-bar__avatar {
    display: flex;

    &-patient {
      align-self: center;
      margin-left: 28px;
      margin-top: 35px;
    }

    & img {
      border-radius: 50%;
      height: 62px;
    }

    & .side-bar__avatar-info {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      & .avatar__name {
        display: flex;
        color: black;
        & span:last-child {
          margin-left: 5px;
        }
      }

      & .avatar__name-birthday {
        letter-spacing: 0.15px;
        color: #515151;
        margin: 4px 0 8px 0;
      }

      & .avatar__name-phone {
        letter-spacing: 0.15px;
        color: $primary80;
      }
    }

    margin-bottom: 20px;
  }

  .request-by {
    letter-spacing: 0.15px;
    color: #777777;
    font-size: 15px;
  }

  .requested {
    margin: 8px 0 30px 0;
    display: flex;
    align-items: center;
    & span:first-child {
      font-size: 18px;
    }

    & span:last-child {
      margin-left: 5px;
      color: grey;
    }
  }

  & .symptoms {
    display: flex;
    flex-wrap: wrap;

    & .symptoms-element {
      margin: 0 0 6px 3px;
    }
  }

  & .address {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    & .address-init {
      letter-spacing: 0.15px;
      color: #777777;
      margin-bottom: 17px;
    }

    & .address_additional {
      display: flex;

      & .address__full {
        letter-spacing: 0.5px;
        color: $primary80;
        display: flex;
        flex-direction: column;
        margin: 0 0 9px 16px;
        & span {
          color: #1771b7;
        }
      }
    }
  }

  & .side-bar__btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  & .side-bar__clock {
    display: flex;
    margin-top: 22px;
    flex-direction: column;
    &-typography {
      display: inline-block;
    }
    &-time {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #717579;
      opacity: 0.6;
    }
    &-input {
      display: flex;
      column-gap: 10px;

      &_item {
        flex-basis: 30%;
      }
    }
    &-container {
      display: flex;
      flex-direction: row;
    }
  }

  & .approximate-eta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  & .approximate-eta {
    position: relative;
    width: 100%;
    white-space: nowrap;
    border: 1px solid $core30;
    box-sizing: border-box;
    border-radius: $borderRadius10;
    padding: 13px 20px 8px;
    margin-bottom: 30px;
    color: $primary80;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 60%;
      margin-top: 8px;
    }
    &_container-extra {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .typography {
        margin-left: 10px;
      }
    }
    &_time {
      font-size: 34px;
      line-height: 38px;
      font-weight: 600;

      &__unit {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-left: 8px;
        margin-right: 16px;
        color: $primary80;
      }
    }

    &__edit-button {
      position: absolute;
      top: 11px;
      right: 23px;
    }
  }
  & .add-services {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 26px;
    &_text {
      color: $primary80;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
    }
    & .icon-component {
      width: 15px;
      height: 21px;
    }
  }
  & .side-bar__services {
    height: 100%;
    margin-bottom: 24px;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
  & .view-services {
    &_text {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #777777;
      margin: 40px 0 30px 0;
      display: block;
    }
    &_name {
      width: 62%;
      display: inline-block;
    }
    &_container {
      display: flex;
      margin-bottom: 12px;
    }
    &_price {
      margin-left: 27px;
    }
    &_price-status {
      color: $primary80;
    }
    &_status {
      margin-left: 29px;
    }
    &_waiting-status {
      color: #717579;
    }
  }
  & .finalView-services {
    border-radius: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);

    .additional-services {
      padding: 4px 16px 10px 16px;
    }

    &_text {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #777777;
      margin: 40px 0 30px 0;
      display: block;
    }

    &_name {
      width: 62%;
    }
    &_container {
      margin-bottom: 12px;
    }
    &_price {
      margin-left: 27px;
    }
    &_price-status {
      color: $primary80;
    }
    &_status {
      margin-left: 29px;
    }
    &_waiting-status {
      color: #717579;
    }
  }
  & .side-bar__add-services {
    margin-top: 40px;
  }
  & .side-bar__view-services {
    margin-top: 26px;
  }
  & .side-bar__textarea {
    &-title {
      font-style: italic;
      padding: 15px;
      margin-top: 12px;
    }
    &--icon {
      position: absolute;
      cursor: pointer;
      right: 8px;
      bottom: 15px;
      z-index: 3;
      &-container {
        position: relative;
      }
    }
    &--comment {
      background: #f8f8f8;
      border-radius: 4px;
      padding: 16px;
      font-style: italic;
      margin-top: 17px;
      white-space: pre-wrap;
      word-break: break-word;
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #777777;
    }
  }

  & .side-bar__patient-comment {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    &-header {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #777777;
    }
    &-text {
      font-style: italic;
      margin-top: 16px;
    }
  }

  & .component-service-request {
    width: 82%;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 16px 0 16px;
    &_title {
      display: flex;
      justify-content: space-between;
    }
  }
  & .component-service-request_price-block {
    margin-top: 16px;
    padding-right: 16px;
    padding-left: 20px;
    width: calc(82% + 32px);
    transform: translateX(-1px);
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(243, 243, 243, 0.6);
    border-top: 1px dashed #b8b8b8;

    &_what-is {
      text-decoration: underline;
    }
    &__title {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }

    &_container-price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &_summary {
      font-weight: 500;
    }

    &_right,
    &_left {
      position: absolute;
      width: 15px;
      height: 30px;
      top: -15px;
      box-shadow: 0px 1px 4px inset rgba(0, 0, 0, 0.2);
      background: white;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 28px;
        background-color: white;
        top: 1px;
        left: -4px;
        border-radius: 0 4px 4px 0;
      }
    }

    &_right {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      right: 0;

      &:after {
        left: 11px;
        border-radius: 4px 0 0 4px;
      }
    }
    &_left {
      border-left: 0;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      left: 0;
    }
  }
  & .view-services_price-total {
    font-size: 34px;
    line-height: 38px;
    margin-left: 15px;
    margin-right: 30px;
  }
  .side-bar__doctor-popup {
    &__container {
      margin-top: 8px;
      margin-bottom: 30px;
    }
    &__title {
      font-size: 20px;
      line-height: 28px;
    }
    &__content {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .assigned-to {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    &__name {
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #777777;
      &-doctor {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }
  }

  .separator {
    width: 100%;
  }

  .container-map {
    margin-top: 30px;
  }

  .patient-sidebar {
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .group-card {
    margin-top: 20px;
  }

  .examination {
    .patient-sidebar {
      border: none;
      padding: 0;
    }
  }
}

.side-bar__textarea--comment-title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #777777;
}

.new-services-container {
  display: flex;
  flex-direction: column;
}

.side-bar-wrapper {
  height: 70vh;
  overflow: hidden;
}

.side-bar {
  &__avatar {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;

    .avatar__name {
      span {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }
  }

  &__user {
    margin-top: 15px;
    display: flex;
  }

  &__request-indicator {
    padding: 2px 16px;
    display: inline-block;
    color: $primary80;
    background: $primary20;
    border-radius: 8px;
    line-height: 24px;
    font-size: 16px;
  }
}

.total-visit-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
