.pmh-block {
  .textarea-common-component_textarea {
    margin-top: 12px;
    margin-bottom: 0;
  }

  .textarea-common-component {
    margin-top: 0;
  }
  &_item {
    align-items: center;
    display: flex;
  }

  &_add-button {
    display: flex;
    color: #1771b7;
    align-items: center;
    margin-top: 15px;

    svg {
      width: 16px;
      height: 16px;
    }
    .icon-component {
      margin-right: 5px;
    }
  }

  .emr-notes-wrapper_error-text {
    display: none;
  }
}
