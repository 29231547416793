@import "src/styles/colors";
.dashboard-card {
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: $borderRadius2;
  padding: 16px;
  margin-bottom: 24px;
  box-sizing: border-box;

  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .card-title {
    display: block;
  }
}

.dashboard-time {
  &__container {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
  }
  .dashboard-title {
    display: block;
    font-size: 21px;
  }

  .time-wrapper {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #dadce0;
    margin-bottom: 28px;

    .delta {
      margin-top: 20px;
    }

    &.last {
      border-bottom-width: 0;
      margin-bottom: 0;
    }
  }
}
