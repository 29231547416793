@import "src/styles/colors";
.list-requests-details {
  width: 100%;
  height: 50%;
  padding: 30px 20px 30px 10px;
  overflow: auto;

  &_item {
    padding: 20px 20px 15px 20px;
    width: 100%;
    height: 114px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    &_specialty {
      height: 20px;
    }

    &--current {
      box-shadow: $itemRequestshadow;
      border: 1px solid $primary70;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &_header,
    &_footer {
      display: flex;
      justify-content: space-between;

      &_status {
        display: flex;
        align-items: center;
      }
    }

    &_header {
      &_doctor {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_footer {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &_time {
        &_time {
          margin-left: 30px;
        }
      }
      &_button {
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon-component {
          margin-left: 11px;
        }
      }
    }
  }
}

@media (max-width: 1330px) {
  .list-requests-details {
    &_item {
      height: 129px;
      &_footer {
        &_time {
          display: flex;
          flex-direction: column;
          &_time {
            margin-left: 0;
          }
        }
      }
    }
  }
}
