@import "src/styles/colors";
.container-details-appointment-admin {
  width: 100%;
  min-height: 300px;

  .popup-component-common-content_body {
    margin-top: 24px;
    .cancel-request-sub-title {
      font-size: 18px;
      letter-spacing: 0.15px;
      color: #090b0b;
      display: flex;

      &.activated {
        margin-bottom: 30px;
      }
    }

    .textarea-common-component {
      margin-top: 14px;
      margin-bottom: 30px;
    }
    .textarea-common-component_textarea {
      margin-bottom: 0;
      margin-top: 11px;
    }
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    button {
      &:last-of-type {
        margin-left: 30px;
      }
    }
  }

  .side-bar_patient {
    margin-top: 35px;
  }

  .doctor-info-event {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &_container-data {
      display: flex;
      justify-content: space-between;
    }

    &_extra {
      border: 1px solid #dadce0;
      box-sizing: border-box;
      border-radius: $borderRadius2;
      padding: 12px 24px;
      line-height: 24px;
    }
  }

  .list-appointments {
    width: 100%;
    margin-top: 10px;

    &_item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #dadce0;
      padding: 20px 25px 20px 0;

      .typography {
        &:first-child {
          line-height: 24px;
          margin-bottom: 4px;
        }

        &:nth-child(2) {
          line-height: 24px;
          margin-bottom: 11px;
        }
      }

      &_request {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .shift-range-component {
    padding: 12px 24px;
    display: flex;
    border: 1px solid #dadce0;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    margin-top: 32px;
    justify-content: space-between;

    ul {
      .icon-component {
        width: 25px;
      }
    }

    &_dates {
      display: flex;
      flex-direction: column;

      .typography {
        &:first-of-type {
          margin-bottom: 4px;
        }
      }
    }
  }

  &_status {
    margin: 25px 0;
  }

  .view-services .typography-p {
    font-weight: normal;
  }

  &_request-type {
    margin-top: 10px;
  }
}

.admin-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &_fullname {
    margin-top: 8px;
  }

  &_create-at {
    margin-top: 4px;
  }

  &_title {
    letter-spacing: 0.15px;
    color: #777777 !important;
    font-size: 15px;
  }
}
