@import "src/styles/colors";
.chat-block {
  position: fixed;
  right: -440px;
  height: 100vh;
  display: flex;
  top: 0;
  width: 0;
  z-index: 1000;
  box-shadow: $shadowSidebar;
  transition: right 1s;
  padding: 33px 24px 16px 30px;
  box-sizing: border-box;
  flex-direction: column;
  background: white;
  border-bottom-left-radius: $borderRadius4;
  border-top-left-radius: $borderRadius4;

  &.details-request-patient {
    .chat-block_navigation {
      justify-content: flex-end;
      .icon-component:first-of-type {
        display: none;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  &_open {
    width: 436px;
    right: 0;
  }

  &_recipient {
    display: flex;
    align-items: center;
    width: calc(100% + 54px);
    padding: 27px 24px 13px 30px;
    transform: translateX(-30px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    &_img {
      border-radius: 50%;
      height: 44px;
      width: 44px;
      margin-right: 12px;
    }

    &_name {
      font-size: 28px;
      line-height: 56px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100% + 24px);
    overflow: auto;
    height: calc(100vh - 166px - 16px - 69px - 16px - 33px);
    padding-top: 16px;
  }
  &_messages {
    padding-right: 24px;
    overflow: scroll;
    width: 100%;
    &_item {
      display: flex;
      margin-bottom: 4px;

      &_mess {
        background: $core30;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: $core100;
        padding: 12px 16px;
        border-radius: 4px;
        margin-right: 20px;
        border-bottom-left-radius: 0;
      }

      &_next-alias {
        margin-bottom: 16px !important;
      }

      &_my {
        justify-content: flex-end;
        .chat-block_messages_item_mess {
          text-align: right;
          color: $core100;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 0;
          background: $primary20;
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }

  &_navigation {
    display: flex;
    justify-content: space-between;
  }

  &_input-block {
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.08);
    background: white;
    width: calc(100% + 54px);
    padding: 0 24px 13px 30px;
    transform: translateX(-30px);
    position: relative;

    .icon-component {
      cursor: pointer;
      position: absolute;
      right: 33px;
      z-index: 100000000000;
      bottom: 30px;
    }

    &_time {
      font-style: italic;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: #757575;
      margin-top: 12px;
      display: flex;

      a {
        text-decoration: underline;
        display: inline;
        color: $primary80;
      }
    }

    .textarea-common-component_textarea {
      margin-top: 12px;
      margin-bottom: 0;
      padding-right: 40px;
    }

    .textarea-common-component {
      margin-top: 4px;
    }
  }
}

.button-chat {
  img {
    margin-right: 10px !important;
  }
}

@media (max-width: 767px) {
  .chat-block {
    border-radius: 0;

    &_open {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .chat-block {
    &_input-block {
      box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.08);
      background: white;
      width: calc(100% + 54px);
      padding: 0 24px 13px 30px;
      transform: translateX(-30px);

      &_time {
        font-size: 12px;
      }
    }

    &_messages {
      &_item {
        &_mess {
          font-size: 14px;
          padding: 8px 12px;
          line-height: 20px;
        }

        &_next-alias {
          margin-bottom: 12px !important;
        }
      }
    }

    &_recipient {
      display: flex;
      margin-top: 27px;
      align-items: center;
      width: calc(100% + 54px);
      padding: 0 24px 13px 30px;
      transform: translateX(-30px);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

      &_name {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .chat-block_is-patient {
    transform: translateX(-16px);
    width: calc(100% + 32px);
    position: relative;
  }
}
