.container-pcp-spec {
  width: 100%;
  padding-right: 40px;
  padding-left: 30px;

  &_header {
    display: flex;
    justify-content: space-between;

    button {
      background: #f3f8fb;
      border-radius: 4px;
      align-items: center;
      display: flex;
      border: 1px solid #1771b7;
      width: 142px;
      height: 36px;
      justify-content: center;

      .icon-component {
        margin-right: 15px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &_list {
    height: calc(100vh - 255px);
    flex-wrap: wrap;
    margin-top: 20px;
    overflow: auto;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    &_item {
      margin-right: 10px;
      margin-top: 20px;
      position: relative;
      padding: 20px;
      width: calc(50% - 20px);
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 10px;

      &:nth-of-type(2n) {
        margin-left: 10px;
        margin-right: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:nth-of-type(2) {
        margin-top: 0;
      }

      &_block {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        span {
          text-transform: capitalize;
          &:nth-of-type(2) {
            margin-top: 8px;
          }
        }

        &:first-of-type {
          margin-top: 0;
        }
      }

      .button-menu {
        position: absolute;
        right: 5px;
        top: 13px;
      }
    }
  }
}

.pcp-container-content-content {
  width: 726px !important;
}

.confirmation-delete-doctor-content {
  .popup-component-common-content_body {
    height: 20px;
  }
}
