@import "src/styles/colors";
.emr-notes-checkbox {
  border: 1px solid $primary80;
  border-radius: $borderRadius2;
  display: inline-block;
  min-height: 42px;
  padding: 10px 5px;
  box-sizing: border-box;
  margin: 4px 5px 0 5px;
  position: relative;

  &_error {
    border-color: #b00020;
  }

  .checkbox-item {
    display: inline-flex;
    white-space: nowrap;
  }
}
