.round-wrapper {
  position: relative;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
  margin-top: 53px;

  .round-background {
    width: 124px;
    height: 124px;
    border-radius: 300px;
    position: absolute;
    top: 3px;
    left: 3px;
  }
}
