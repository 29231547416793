@use "sass:meta" as ---cwdeglz70dv;@import "./variables";
@import "./time-column";

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: $time-selection-bg-color;
  color: $time-selection-color;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid $calendar-border;
  border-top: none;
  border-left: none;
  min-height: 0;

  .rbc-time-gutter {
    white-space: nowrap;
  }

  .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid $cell-border;
  }

  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; // should not shrink below height
  flex-direction: row;

  &.rbc-overflowing {
    border-right: 1px solid $cell-border;
  }

  .rbc-rtl &.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid $cell-border;
  }

  > .rbc-row:first-child {
    border-bottom: 1px solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $cell-border;
  }

  // .rbc-gutter-cell {
  //   flex: none;
  // }

  // > .rbc-gutter-cell + * {
  //   width: 100%;
  // }
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid $cell-border;

  .rbc-rtl & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $cell-border;
    flex-shrink: 0;
  }
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  overflow-y: initial;
  position: relative;

  > .rbc-time-gutter {
    flex: none;
  }

  > * + * > * {
    border-left: 1px solid $cell-border;
  }

  .rbc-rtl & > * + * > * {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: -20px;
  right: -6px;
  height: 1px;
  margin: 0 6px;
  background-color: #fc9c0e;
  pointer-events: none;

  &:after {
    content: "";
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #fc9c0e;
    position: absolute;
    left: -1px;
    top: -6px;

    border: none;
  }
}

.rbc-day-slot.rbc-time-column:last-of-type {
  border-right: 1px solid $cell-border;
}

.rbc-time-view {
  border-right: none;
}

.rbc-header:last-of-type {
  .header-day-cell_number-day {
    border-right: 1px solid $cell-border;
  }
}

;@include ---cwdeglz70dv.load-css("sass-embedded-legacy-load-done:31");