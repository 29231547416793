.veradigm-button-container {
  form {
    opacity: 0;
    height: 0;
    width: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
  }

  .no-default-style-button {
    padding: 0;
  }
}
