@import "src/styles/colors";
.custom-pusher-toaster {
  &_container-info {
    display: flex;
    &_block-text {
      width: 100%;
      &_title {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        color: $core70;
        display: flex;
        justify-content: space-between;

        path {
          fill: #5f6368;
        }
      }
      &_text {
        margin-top: 15px;
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        color: $core100;
      }
    }
  }
  &_button-navigate {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 576px) {
  .custom-pusher-toaster {
    width: 100%;
  }
}
