@import "src/styles/colors";
.container-map {
  width: 100%;
  display: flex;
  flex-direction: column;

  &_address-container {
    margin-top: 17px;
    display: flex;
    align-items: center;

    &_img {
      margin-right: 16px;
      cursor: initial;
      svg > path {
        fill: #63727e;
      }
    }
    &_text {
      margin-right: 16px;
    }

    &_out-working-zone {
      border-radius: 4px;
      background: $warningLight;
      padding: 8px 16px;
      margin-left: auto;
      display: flex;
      white-space: nowrap;
      color: $core80;
      font-size: 12px;
      align-items: center;

      svg {
        margin-right: 8px;
        path {
          fill: $warning;
        }
      }
    }
  }

  &_map {
    margin-top: 9px;
    border: 1px solid $core20;
    box-sizing: border-box;
    border-radius: 2px;
  }

  &__comment {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  &__text {
    font-style: italic;
    margin-top: 8px;
  }
}
