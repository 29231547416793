.admin-page-dashboard {
  display: flex;
  justify-content: space-between;
  width: 1298px;
  margin: 0 auto;
  flex-direction: column;

  .dashboard-table-admin {
    .emr-page-container_table_row {
      .table-header-cell {
        height: 100% !important;
        > span {
          white-space: initial;
        }
      }
    }
  }

  .emr-page-container_table {
    min-height: 300px;
  }

  &_containerCard {
    display: flex;
    justify-content: space-between;

    .chart-container {
      height: 207px;
    }

    .admin-page_-dashboard-statistics_container {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
  }
  &_filterTime {
    margin: 40px 0;
    display: flex;
    justify-content: flex-end;
  }

  .one,
  .two,
  .three,
  .four {
    //height: 260px;
  }
  .one {
    width: 306px;
  }

  .two {
    width: 305px;
  }

  .three {
    width: 417px;
  }

  .four {
    width: 197px;
    .round-wrapper {
      margin: 53px auto 10px;
    }
  }

  .time-components-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .stat-wrapper {
    display: flex;
    justify-content: space-between;

    .item {
      width: 33%;

      .title {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}
