@import "src/styles/colors";

.component-schedule-events {
  width: 100%;
  display: flex;

  &_day {
    .rbc-time-view {
      margin-top: 40px;
    }
  }

  &_inline-calendar-container {
    box-sizing: border-box;
    width: 220px;
    min-width: 220px;
    height: 100%;

    .react-datepicker {
      padding: 0 0 30px 0;
      box-shadow: none;
    }
  }

  &_schedule-container {
    box-sizing: border-box;
    $width: calc(100vw - 220px - 64px);
    height: 100%;
    min-width: 917px;
    width: $width;

    .calendar-modes-block {
      min-width: 917px;
      width: $width;
    }
    &_scroll-area {
      min-width: 917px;
      width: $width;
      box-sizing: border-box;

      padding-right: 64px;
      padding-left: 100px;
      overflow: scroll;
      height: calc(100vh - 190px);
    }

    .container-events-patient {
      padding-left: 100px;
      box-sizing: border-box;
      height: calc(100vh - 190px);

      .ReactVirtualized__Grid.ReactVirtualized__List {
        padding-right: 64px;
      }
    }
  }

  .rbc-time-header {
    position: sticky;
    top: 0;
    z-index: 1001;
    background: white;
  }
}

.schedule-date-picker {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;

  &_date {
    span {
      font-size: 16px;
      letter-spacing: 1.25px;
      color: $core70;
    }
  }

  &_control {
    display: flex;
    align-items: center;

    &_decrease,
    &_increase {
      path {
        fill: $core70;
      }
    }

    &_today {
      cursor: pointer;
      margin: 0 16px;
      font-size: 16px;
      text-transform: capitalize;
      color: $core70;

      &.active {
        color: $primary80;
      }
    }
  }
}

.header-day-cell {
  letter-spacing: 1.25px;

  color: $core70;

  &_name-day {
    text-transform: uppercase;
    height: 20px;
    margin-bottom: 4px;
    font-size: 16px;
  }

  &_number-day {
    border: 1px solid #dadce0;
    border-top: none;
    border-right: none;
    height: 56px;
    color: $core70;
    font-size: 24px;
    line-height: 20px;
    text-transform: uppercase;
    padding-bottom: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    span {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_selected {
    .header-day-cell {
      &_name-day {
        color: $primary80;
      }

      &_number-day {
        span {
          color: white;
          background: $primary80;
        }
      }
    }
  }
}

.custom-toolbar-calendar {
  position: sticky;
  top: 0;
  z-index: 1002;

  &_date {
    height: 40px;
    white-space: nowrap;
    margin-left: 68px;
    font-size: 28px;
    color: #090b0b;
  }

  &_requests {
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    white-space: nowrap;
    text-transform: lowercase;
    margin-right: 60px;
    margin-left: 16px;
    font-size: 18px;
    line-height: 24px;
    color: #63727e;
  }

  &_buttons {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: -63px;
    align-items: center;

    &_next,
    &_prev {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_prev {
      left: -8px;
    }

    &_next {
      right: -19px;
    }
  }

  &_buttons-day {
    background: white;
    bottom: -40px;
  }
  &_week,
  &_day {
    background: #1771b7;
    width: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    border-radius: 4px;
    text-transform: uppercase;
  }

  &_week {
  }

  &_day {
  }
}

.calendar-modes-block {
  display: flex;
  padding-left: 100px;
  box-sizing: border-box;
  padding-right: 64px;
  padding-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  &_title {
    text-transform: capitalize;
    margin-right: 24px;
    font-size: 28px;
    color: #090b0b;
  }

  &_month-title {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 16px;
  }

  &_time-mode {
  }
}
