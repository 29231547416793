@import "src/styles/colors";
.doctor-page-personal-btn-container {
  display: flex;
  justify-content: center;
  &_btn {
    background: #fff;
    border: 1px solid #dbdce0;
    box-sizing: border-box;
    width: 220px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000;
    outline: none;
    padding: 8px 10px;

    &_active {
      background-color: $primary10;
      color: $primary80;
      border: 1px solid $primary80;
    }
  }
  button:first-child {
    border-top-left-radius: $borderRadius4;
    border-bottom-left-radius: $borderRadius4;
  }
  button:last-child {
    border-top-right-radius: $borderRadius4;
    border-bottom-right-radius: $borderRadius4;
  }
}

@media screen and (max-width: 567px) {
  .doctor-page-personal-btn-container {
    flex-direction: column;

    button:first-child {
      border-top-left-radius: $borderRadius4;
      border-bottom-left-radius: 0;
      border-top-right-radius: $borderRadius4;
    }
    button:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: $borderRadius4;
      border-bottom-left-radius: $borderRadius4;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  &_item {
    transition: 0.2s ease-in;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #fff;
    border: 1px solid #dbdce0;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #63727e;
    outline: none;
    white-space: nowrap;
    padding: 8px 10px;

    &_active {
      color: $primary80;
      border: 1px solid $primary80;
    }
  }
  button:first-child {
    border-top-left-radius: $borderRadius4;
    border-bottom-left-radius: $borderRadius4;
  }
  button:last-child {
    border-top-right-radius: $borderRadius4;
    border-bottom-right-radius: $borderRadius4;
  }
}

@media screen and (max-width: 567px) {
  .btn-group {
    flex-direction: column;

    button:first-child {
      border-top-left-radius: $borderRadius4;
      border-bottom-left-radius: 0;
      border-top-right-radius: $borderRadius4;
    }
    button:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: $borderRadius4;
      border-bottom-left-radius: $borderRadius4;
    }
  }
}
